import React from "react";
import { useTranslation } from "react-i18next";
import "./AboutPage.css";
import divider from "../assets/icons/divider.png";
import poolView from "../assets/gallery/pool/natura_pool1.webp";
import restaurantView from "../assets/gallery/food/natura_breakfast6.webp";
import roomImage from "../assets/gallery/inside/natura_rooms5.webp";
import roomImage2 from "../assets/gallery/inside/natura_rooms7.webp";
import roomImage3 from "../assets/gallery/inside/natura_rooms4.webp";
import EmailSubscription from "../components/EmailSubscription";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const AboutPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="about-page">
      <Helmet>
        <title>{t("About Us | Hotel Natura")}</title>
        <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
        <meta
          name="description"
          content={t(
            "Learn more about Hotel Natura, a tranquil retreat blending luxurious comfort, local cuisine, and beautiful natural surroundings."
          )}
        />
      </Helmet>
      <section className="about-header">
        <img src={divider} alt={t("divider")} className="divider-icon" />
        <h1>{t("HOTEL NATURA MAKING YOUR HOLIDAYS GREAT!")}</h1>
        <p className="about-intro">
          {t("Nestled in nature, our hotel offers tranquility, relaxation, and a luxurious escape from city life.")}
        </p>
      </section>

      <section className="about-content">
        <div className="about-text">
          <h2>{t("Where Comfort Meets Nature")}</h2>
          <p>
            {t("Hotel Natura boasts a poolside setting, a spacious sun deck, a salt room, sauna, and jacuzzi. Our restaurant serves homemade dishes crafted with fresh, pesticide-free ingredients, ensuring a delightful culinary experience.")}
          </p>
          <p>
            {t("Each morning, enjoy a rich buffet breakfast by the pool, featuring local and homemade products.")}
          </p>
        </div>
        <div className="about-image">
          <img src={poolView} alt={t("Luxury poolside")} />
        </div>
      </section>

      <section className="about-content reverse">
        <div className="about-image">
          <img src={restaurantView} alt={t("Fine dining experience")} />
        </div>
        <div className="about-text">
          <h2>{t("Indulge in Local Cuisine")}</h2>
          <p>
            {t("Our chefs bring you the best of traditional and modern flavors, using organic and locally sourced ingredients.")}
          </p>
          <p>
            {t("Whether dining indoors or al fresco, each meal is an experience designed to satisfy the senses.")}
          </p>
        </div>
      </section>

      <section className="about-rooms">
        <h2>{t("ROOMS")}</h2>
        <p className="about-intro">
          {t("Hotel Natura offers four distinct room types, all designed for a relaxing stay. Every room features a wardrobe, desk, satellite TV, minibar, safe, and mosquito nets.")}
        </p>
        <div className="about-rooms-content">
          <div className="about-room-item">
            <img src={roomImage} alt={t("Scenic Pool View")} />
            <h3>{t("Scenic Pool View")}</h3>
            <p>
              {t("The first room type has a main area, a bathroom with a shower, and a balcony with a picturesque view of the pool.")}
            </p>
          </div>

          <div className="about-room-item">
            <img src={roomImage2} alt={t("Spacious & Cozy")} />
            <h3>{t("Spacious & Cozy")}</h3>
            <p>
              {t("The second room type includes a shower-equipped bathroom and a hallway but no balcony. The third type mirrors the second but offers two single beds instead of a double bed.")}
            </p>
          </div>

          <div className="about-room-item">
            <img src={roomImage3} alt={t("Perfect for Families & Groups")} />
            <h3>{t("Perfect for Families & Groups")}</h3>
            <p>
              {t("This family-friendly option includes two separate rooms: one with a double bed and attached bathroom, the other with two single beds and an additional bathroom. Both have garden-view balconies.")}
            </p>
          </div>
        </div>
        <button onClick={() => window.location.href='/rooms/'} className="about-button">
    {t("ALL OUR ROOMS")}
</button>
      </section>
      
      <EmailSubscription />
    </div>
  );
};

export default AboutPage;