import React from "react";
import { useTranslation } from "react-i18next";
import "./FeatureGrid.css";
import SpaImage from "../assets/spa.webp";
import PoolImage from "../assets/bazen.webp";
import JacuzziImage from "../assets/jacuzzi.webp";
import CityImage from "../assets/umag.webp";
import VideoBg from "../assets/video.webp";
import PlayIcon from "../assets/icons/play.png";

const FeatureGrid = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="video-section" style={{ backgroundImage: `url(${VideoBg})` }}>
                <div className="video-wrapper">
                    <a href="https://www.youtube.com/watch?v=m293jMC_His&ab_channel=GradUmag" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       aria-label="Watch video about Umag from 2021 on YouTube">
                        <img src={PlayIcon} alt={t("Play")} className="play-icon" />
                    </a>
                </div>
                <p className="video-text">{t("Create Memories that will never be forgotten!")}</p>
            </section>

            <div className="feature-grid">
                <div className="feature-item">
                    <img src={SpaImage} alt={t("Spa & Sauna")} />
                    <p>{t("Spa & Sauna")}</p>
                </div>
                <div className="feature-item">
                    <img src={PoolImage} alt={t("Amazing Pool")} />
                    <p>{t("Amazing Pool")}</p>
                </div>
                <div className="feature-item">
                    <img src={JacuzziImage} alt={t("Stunning Jacuzzi")} />
                    <p>{t("Stunning Jacuzzi")}</p>
                </div>
                <div className="feature-item">
                    <img src={CityImage} alt={t("Beautiful City of Umag")} />
                    <p>{t("Beautiful City of Umag")}</p>
                </div>
            </div>
        </>
    );
};

export default FeatureGrid;