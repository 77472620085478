import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import Logo from "../assets/crni-logo.png";

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <a href="/" aria-label="Go to Hotel Natura Homepage">
                        <img src={Logo} alt="Hotel Natura Logo" />
                    </a>
                </div>
                <div className="footer-about">
                    <h3>{t("WE ARE HERE TO GIVE YOU THE MOST PERFECT HOLIDAYS")}</h3>
                    <p>
                        {t(
                            "Just the name of the hotel reveals a lot about the place where it’s located and which benefits are offered to its guests. For most people, vacation is a synonym for nature, greenery, peace, and relaxation far away from everyday city noise. If you are the kind of person that loves nature and peace, then Hotel Natura is the right choice for you."
                        )}
                    </p>
                </div>
                <div className="footer-links">
                    <div className="footer-column">
                        <h4>{t("SITEMAP")}</h4>
                        <ul>
                            <li><a href="/">{t("HOME")}</a></li>
                            <li><a href="/about-us/">{t("ABOUT US")}</a></li>
                            <li><a href="/rooms/">{t("OUR ROOMS")}</a></li>
                            <li><a href="/gallery/">{t("GALLERY")}</a></li>
                            <li><a href="/contact-us/">{t("CONTACT US")}</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>{t("QUICKLINKS")}</h4>
                        <ul>
                            <li><a href="/privacy-policy/">{t("PRIVACY POLICY")}</a></li>
                            <li><a href="/terms-of-use/">{t("TERMS AND CONDITIONS")}</a></li>
                            <li><a href="/how-to-submit-a-written-complaint/">{t("HOW TO SUBMIT A WRITTEN COMPLAINT")}</a></li>
                        </ul>
                        <h4 className="footer-social-heading">{t("Follow Us")}</h4>
                        <div className="footer-social">
                <a href="https://www.facebook.com/hotelnaturavilanija" target="_blank" rel="noopener noreferrer" aria-label="Visit our Facebook page">
                    <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                </a>
                <a href="https://www.instagram.com/hotel_natura_vilanija/" target="_blank" rel="noopener noreferrer" aria-label="Visit our Instagram profile">
                    <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </a>
            </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>
                    © {currentYear} Hotel Natura | {" "}
                    Powered by <a href="https://dandaweb.com" target="_blank" rel="noopener noreferrer">D&A Smart Solutions</a> | {" "}
                    {t("All rights reserved.")}  
                </p>
            </div>
        </footer>
    );
};

export default Footer;