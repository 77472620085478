import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import heroImage1 from "../assets/heroimage1.webp";
import heroImage2 from "../assets/heroimage2.webp";
import heroImage3 from "../assets/heroimage3.webp";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(true);

const slides = [
    { image: heroImage1, title: t("AN EXTRAORDINARY HOLIDAY AWAITS, SURPASSING YOUR WILDEST DREAMS.") },
    { image: heroImage2, title: t("DREAM ON! A WONDERFUL HOLIDAY AWAITS YOU IN OUR HOTEL.") },
    { image: heroImage3, title: t("A WONDERFUL HOLIDAY AWAITS YOU BEYOND YOUR DREAMS.") }
];

    const extendedSlides = [...slides, slides[0]];

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const nextSlide = () => {
        if (currentIndex >= slides.length) return;

        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => prevIndex + 1);

        setTimeout(() => {
            if (currentIndex === slides.length - 1) {
                setIsTransitioning(false);
                setCurrentIndex(0);
                setTimeout(() => setIsTransitioning(true), 50);
            }
        }, 600);
    };

    return (
        <div className="hero">
            <div
                className="hero-slider"
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: isTransitioning ? "transform 0.6s ease-in-out" : "none",
                }}
            >
                {extendedSlides.map((slide, index) => (
                    <div key={index} className={`hero-slide ${index === currentIndex ? 'hero-slide-active' : ''}`}>
                        <img src={slide.image} alt="Hero Background" />
                        <div className="hero-overlay"></div>
                        <div className="hero-text">
                            <h1>{slide.title}</h1>
                            <button onClick={() => window.location.href='/rooms/'} className="hero-button">
                                {t("Explore Our Rooms")}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HeroSection;