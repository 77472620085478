import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./ContactPage.css";
import HeroImage from "../assets/heroimage3.webp";
import DividerIcon from "../assets/icons/divider.png";
import EmailSubscription from "../components/EmailSubscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [alert, setAlert] = useState({ message: "", type: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { name, email, subject, message} = formData;

    if (!name || !email || !subject || !message) {
      setAlert({
        message: t("Please fill out all fields."),
        type: "error",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch("/submit_contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData),
      });

      const result = await response.text();

      if (result === "success") {
        setAlert({ message: t("Thank you! We'll contact you soon."), type: "success" });
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        setAlert({ message: t("There was an error. Please try again."), type: "error" });
      }
    } catch {
      setAlert({ message: t("An unexpected error occurred."), type: "error" });
    }

    setTimeout(() => setAlert({ message: "", type: "" }), 5000);
  };

  return (
    <div>
      <Helmet>
            <title>{t("Contact Us | Hotel Natura")}</title>
            <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
            <meta
                name="description"
                content={t(
                    "Contact Hotel Natura for bookings, inquiries, or additional information about your stay and our amenities."
                )}
            />
        </Helmet>
      <section className="hero-section" style={{ backgroundImage: `url(${HeroImage})` }}>
        <div className="hero-overlay">
          <div className="hero-content">
            <h1>{t("We’d Love to Hear from You")}</h1>
            <p>{t("Have a question or need assistance with your stay? Reach out to us — we're here to make your experience exceptional.")}</p>
          </div>
        </div>
      </section>

      <section className="map-container">
        <div className="map-overlay"></div>
        <iframe
          title={t("hotel-location")}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.590699227539!2d13.57621766554526!3d45.43775154888325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477b63154b05fd3f%3A0x2d9eb234b676effe!2sHotel%20%22Natura%22!5e0!3m2!1sen!2shr!4v1739961325064!5m2!1sen!2shr"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <section className="contact-container">
        <div className="contact-info">
          <div className="contact-box">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            <div>
              <p className="contact-title">{t("RESERVATION NUMBER")}</p>
              <p><a href="tel:+385911256755" className="contact-link"  aria-label="Call Hotel Natura at +385 91 125 6755">+385 91 125 6755</a></p>
            </div>
          </div>
          <div className="contact-box">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
            <div>
              <p className="contact-title">{t("LOCATION")}</p>
              <p>
                <a
                  href="https://maps.google.com/?q=Klija+18b,+Vilanija,+52470+Umag"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link"
                >
                  Klija 18b, Vilanija, 52470 Umag
                </a>
              </p>
            </div>
          </div>
          <div className="contact-box">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <div>
              <p className="contact-title">{t("CONTACT US")}</p>
              <p><a href="mailto:hotelnaturavilanija@gmail.com" className="contact-link" aria-label="Send an email to Hotel Natura">hotelnaturavilanija@gmail.com</a></p>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
        <img src={DividerIcon} alt={t("divider")} className="divider-icon" />
        <h2>{t("Schedule Your Consultation")}</h2>
        <p>{t("If you're uncertain about selecting a room, reach out to us via this form.")}</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="input-row">
            <input type="text" name="name" placeholder={t("Name*")} value={formData.name} onChange={handleChange} required />
            <input type="email" name="email" placeholder={t("Email*")} value={formData.email} onChange={handleChange} required />
          </div>
          <input type="text" name="subject" placeholder={t("Subject")} value={formData.subject} onChange={handleChange} />
          <textarea name="message" placeholder={t("Your Message")} value={formData.message} onChange={handleChange}></textarea>
          <button type="submit">{t("SEND")}</button>
        </form>
      </section>
      <EmailSubscription />
    </div>
  );
};

export default ContactPage;