import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import spinner from "../assets/spinner.svg";
import { useTranslation } from 'react-i18next';

const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [loadingError, setLoadingError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await fetch("https://danda.hr/proxy.php?endpoint=get-stripe-key");

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.publishableKey) {
          const stripe = await loadStripe(data.publishableKey);
          setStripePromise(stripe);
        } else {
          throw new Error("No publishable key returned from the server.");
        }
      } catch (error) {
        setLoadingError(error.message);
        console.error("Error fetching Stripe publishable key:", error);
      }
    };

    fetchStripeKey();
  }, []);

  if (loadingError) {
    return <p>Error loading Stripe: {loadingError}</p>;
  }

  return stripePromise ? (
    <Elements stripe={stripePromise}>{children}</Elements>
  ) : (
    <div className="spinner-container">
        <img src={spinner} alt="Loading Stripe..." className="spinner" />
        <p>{t("Loading secure payment gateway...")}</p>
      </div>
  );
};

export default StripeProvider;