import React from "react";
import PropTypes from "prop-types";
import "./CustomAlert.css";

const CustomAlert = ({ 
  message, 
  onClose, 
  onConfirm = null, 
  showActions = false 
}) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <p>{message}</p>
        {showActions ? (
          <div className="custom-alert-actions">
            <button onClick={onConfirm}>Ok</button>
            <button className="cancel" onClick={onClose}>Cancel</button>
          </div>
        ) : (
          <button onClick={onClose}>Ok</button>
        )}
      </div>
    </div>
  );
};

CustomAlert.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  showActions: PropTypes.bool,
};

export default CustomAlert;