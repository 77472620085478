import React, { useState } from "react";
import "./HotelInfoTabs.css";
import ArrowIcon from "../assets/icons/arrow-right1.png";
import LocationIcon from "../assets/icons/location.svg";
import NatureIcon from "../assets/icons/nature.svg";
import FamilyIcon from "../assets/icons/family.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faTree, faPaw, faBicycle, faUtensils, faWifi, faParking, 
  faShuttleVan, faBell, faGamepad, faBroom, faShieldAlt, 
  faCogs, faSwimmer, faSpa, faGlobe, faWalking, faStar, faMountain, faUmbrellaBeach, faPlane, faCheck
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const HotelInfoTabs = () => {
  const [activeTab, setActiveTab] = useState("facilities");
  const { t } = useTranslation();

const sections = [
  { id: "facilities", label: t("Facilities of Hotel Natura") },
  { id: "directions", label: t("Directions") },
  { id: "prices", label: t("Prices") },
  { id: "info", label: t("Hotel Info")},
];

  const renderContent = () => {
    return (
      <div className="tab-content slide-up">
        {activeTab === "facilities" && (
          <div className="facilities-content">
          <h2>{t("Facilities of Hotel Natura Vilanija")}</h2>
          <div className="facilities-grid">
            <div>
              <h3><FontAwesomeIcon icon={faTree} /> {t("Outdoors")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Picnic area")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Outdoor furniture")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Sun deck")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Garden")}</li>
              </ul>
              
              <h3><FontAwesomeIcon icon={faPaw} /> Pets</h3>
              <p>{t("Pets are allowed on request. Charges may apply.")}</p>
              
              <h3><FontAwesomeIcon icon={faBicycle} /> {t("Activities")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Tour or class about local culture")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Themed dinners (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Bike tours")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Walking tours")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Cycling (Off-site)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Ping-pong")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faUtensils} /> {t("Food & Drink")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Coffee house on site")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Fruit")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Wine/Champagne (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Special diet meals (On request)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Bar")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Restaurant")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faWifi} /> {t("Internet")}</h3>
              <p>{t("WiFi is available in all areas and is free of charge.")}</p>
              
              <h3><FontAwesomeIcon icon={faParking} /> {t("Parking")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Free public parking is available at a location nearby (reservation is not possible).")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Secure parking")}</li>
              </ul>
            </div>
        
            <div>
              <h3><FontAwesomeIcon icon={faShuttleVan} /> {t("Transportation")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Airport drop-off (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Airport pickup (Additional charge)")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faBell} /> {t("Front Desk Services")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Tour desk")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faGamepad} /> {t("Entertainment & Family Services")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Board games/Puzzles")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faBroom} /> {t("Cleaning Services")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Daily housekeeping")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Ironing service (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Dry cleaning (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Laundry (Additional charge)")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faShieldAlt} /> {t("Safety & Security")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Fire extinguishers")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Safe")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faCogs} /> {t("General")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Adults only")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Airport shuttle (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Shuttle service (Additional charge)")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Air conditioning")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Smoke-free property")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Heating")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Packed lunches")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Non-smoking rooms")}</li>
              </ul>
            </div>
        
            <div>
              <h3><FontAwesomeIcon icon={faSwimmer} /> {t("Outdoor Swimming Pool")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("All pools are free of charge")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Seasonal")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Pool/Beach towels")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Beach chairs/Loungers")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Pool bar")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faSpa} /> {t("Spa")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Fitness")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Full-body massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Hand massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Head massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Foot massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Neck massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Back massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Spa lounge/Relaxation area")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Beach umbrellas")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Beach chairs/Loungers")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Pool/Beach towels")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Hot tub/Jacuzzi")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Massage")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Sauna (Additional charge)")}</li>
              </ul>
        
              <h3><FontAwesomeIcon icon={faGlobe} /> {t("Languages Spoken")}</h3>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} /> {t("English")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("German")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Italian")}</li>
                <li><FontAwesomeIcon icon={faCheck} /> {t("Croatian")}</li>
              </ul>
            </div>
          </div>
        </div>
        
        )}
        {activeTab === "directions" && (
        <div className="directions-content">
        <h2>{t("Hotel surroundings")}</h2>
        <p>{t("Guests loved walking around the neighborhood!")}</p>
        <div className="directions-grid">
          <div>
            <h3><FontAwesomeIcon icon={faWalking} /> {t("What's nearby")}</h3>
            <ul>
              <li>Umag Bus Station .......................................... 5.9 km</li>
              <li>Umag Central ATP Stadion Stella Maris ........ 6.4 km</li>
              <li>Church of Saint Mary and Saint Peregrine .... 6.7 km</li>
              <li>Umag Old Town ............................................. 6.9 km</li>
              <li>Umag Town Museum ..................................... 6.9 km</li>
              <li>ACI Marina Umag .......................................... 6.7 km</li>
              <li>Sečovlje Salina Nature Park .......................... 3.5 km</li>
              <li>Portoroz Thermal Spa .................................... 16 km</li>
              <li>Savudrija Port ................................................. 11 km</li>
              <li>Savudrija Riva ................................................ 10 km</li>
            </ul>
          </div>
          <div>
            <h3><FontAwesomeIcon icon={faStar} /> {t("Top attractions")}</h3>
            <ul>
              <li>Tartini Square .............................................. 20 km</li>
              <li>St. George’s Parish Church ........................ 15 km</li>
              <li>Aquapark Istralandia ................................... 15 km</li>
            </ul>
            <h3><FontAwesomeIcon icon={faMountain} /> {t("Natural Beauty")}</h3>
            <ul>
              <li>Ocean/Sea Umag ....................................... 6.5 km</li>
            </ul>
            <h3><FontAwesomeIcon icon={faUmbrellaBeach} /> {t("Beaches in the Neighborhood")}</h3>
            <ul>
              <li>Gradska plaža Pozioi .................................. 6.8 km</li>
              <li>Dante Beach ............................................... 6.5 km</li>
              <li>Laguna Stella Maris Beach ......................... 6.8 km</li>
              <li>Beach Špina ................................................ 9.1 km</li>
              <li>Zlatorog Beach ............................................ 8.3 km</li>
            </ul>
          </div>
          <div>
            <h3><FontAwesomeIcon icon={faPlane} /> {t("Closest Airports")}</h3>
            <ul>
              <li>Trieste Airport ............................................. 86 km</li>
              <li>Pula Airport ................................................. 81 km</li>
              <li>Rijeka Airport ............................................ 123 km</li>
            </ul>
            <h3>{t("How to get to Hotel Natura Vilanija from Trieste Airport")}</h3>
              <ul className="last">
              <li><FontAwesomeIcon icon={faShuttleVan} /> {t("Private shuttle")}</li>
              <li><FontAwesomeIcon icon={faParking} /> {t("Free parking is available")}</li>
            </ul>
          </div>
        </div>
      </div>
      )}
        {activeTab === "prices" && (
        <div className="prices-content">
          <div className="prices-table">
            <div className="prices-header">
              <span>Period</span>
              <span>{t("Prices")}</span>
            </div>
            <div className="prices-row">
              <span>15.6 - 30.6</span>
              <span>135 €</span>
            </div>
            <div className="prices-row">
              <span>30.6 - 7.7</span>
              <span>140 €</span>
            </div>
            <div className="prices-row">
              <span>7.7 - 14.7</span>
              <span>145 €</span>
            </div>
            <div className="prices-row">
              <span>14.7 - 19.8</span>
              <span>150 €</span>
            </div>
            <div className="prices-row">
              <span>19.8 - 26.8</span>
              <span>145 €</span>
            </div>
            <div className="prices-row">
              <span>26.8 - 2.9</span>
              <span>140 €</span>
            </div>
            <div className="prices-row">
              <span>2.9 - 9.9</span>
              <span>135 €</span>
            </div>
            <div className="prices-row">
              <span>9.9 - 16.9</span>
              <span>125 €</span>
            </div>
            <div className="prices-row">
              <span>16.9 - 30.9</span>
              <span>120 €</span>
            </div>
          </div>
        </div>
      )}
        {activeTab === "info" && (
        <div className="info-content">
          <div className="info-grid">
            <div className="info-item">
              <img src={LocationIcon} alt="Location Icon" />
              <h3>VILANIJA - UMAG</h3>
              <p>Klija 18b, Vilanija, 52470 Umag</p>
              <p>
                {t("The name of the hotel itself reveals a lot about the place in which it is located and what amenities it offers to its guests.")}
              </p>
            </div>
            <div className="info-item">
              <img src={NatureIcon} alt="Nature Icon" />
              <h3>{t("FAMILY HOTEL IN NATURE")}</h3>
              <p>
                {t("For most people, vacation is synonymous with nature, greenery, peace, and relaxation away from everyday city noise.")}
              </p>
            </div>
            <div className="info-item">
              <img src={FamilyIcon} alt="Family Icon" />
              <h3>{t("FAMILY ENVIRONMENT")}</h3>
              <p>
                {t("Next to the hotel there is a swimming pool, a wide sunbathing area, a salt room, a sauna, and a jacuzzi.")}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

  return (
    <div className="hotel-info-tabs">
      <div className="tab-buttons">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`tab-button ${activeTab === section.id ? "active" : ""}`}
            onClick={() => setActiveTab(section.id)}
          >
            <img
              src={ArrowIcon}
              alt="Arrow Icon"
              className={`tab-icon ${activeTab === section.id ? "active-icon" : ""}`}
            />
            {section.label}
          </button>
        ))}
      </div>
      <div className="tab-content-wrapper">{renderContent()}</div>
    </div>
  );
};

export default HotelInfoTabs;