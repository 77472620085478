import React from "react";
import { useTranslation } from "react-i18next";
import "./ServicesGrid.css";
import dividerImage from "../assets/icons/divider.png";
import bicyclingImage from "../assets/bicycling.webp";
import excursionImage from "../assets/excursion.webp";
import rentImage from "../assets/rent.webp";
import transfersImage from "../assets/transfers.webp";

const ServicesGrid = () => {
  const { t } = useTranslation();

  const services = [
    {
      title: t("Transfers"),
      description: t(
        "The hotel provides an arranged transfer service. Simply provide us with the flight details (flight number and time) along with the pick-up and drop-off locations for our guests."
      ),
      image: transfersImage,
    },
    {
      title: t("Rent a Car"),
      description: t(
        "The hotel offers the option of car rental and can assist you in arranging this service. All you need to do is contact the hotel reception with the details of the rental duration."
      ),
      image: rentImage,
    },
    {
      title: t("One Day Excursion"),
      description: t(
        "The hotel provides the service of arranging one-day excursions to nearby locations. At the reception, you can choose the date and purchase tickets for organized excursions to Venice, Brijuni, Plitvice Lakes, Baredine Cave, and more."
      ),
      image: excursionImage,
    },
    {
      title: t("Bicycling"),
      description: t(
        "Many cross-country, hillside, and adventurous roads await those who love riding on two wheels. Follow the trails along emerald green rivers, relish the journey through pristine woods, or simply take in a deep breath of fresh air."
      ),
      image: bicyclingImage,
    },
  ];

  return (
    <section className="services-grid">
      <div className="container">
        <img src={dividerImage} alt="Divider" className="divider-icon" />
        <h2 className="title">{t("HOTEL NATURA SERVICES")}</h2>
        <p className="description">
          {t(
            "At Hotel Natura Umag in Vilanija, we provide various additional services. Whether you require airport transfers or wish to explore the nearby stunning locations, feel free to contact us anytime. Our reception is open 24/7, every day, to assist you."
          )}
        </p>
        <div className="grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <div className="image-container">
                <img src={service.image} alt={service.title} className="service-image" />
              </div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesGrid;