import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PaymentForm from "../components/PaymentForm";
import StripeProvider from "../components/StripeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faCheckCircle, faCalendarDays, faUserFriends, faClipboardCheck, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import './BookingPage.css';
import CustomAlert from "../components/CustomAlert";
import doubleRoom from '../assets/rooms/doubleroom.webp';
import doubleBalcony from '../assets/rooms/double_room.webp';
import doubleBalconyPet from '../assets/rooms/double_balcony_petfr.webp';
import quadRoom from '../assets/rooms/quad_room.webp';
import twinRoom from '../assets/rooms/twinroom.webp';
import { useLocation } from "react-router-dom";


const convertPriceToCents = (priceString) => {
  if (typeof priceString === "number") {
    return priceString * 100; // convert to cents
  }

  if (typeof priceString === "string") {
    const numericPrice = parseFloat(priceString.replace(/[^\d.]/g, ""));
    if (isNaN(numericPrice)) {
      console.error("Invalid priceString:", priceString);
      return 0; // Default to 0 if parsing fails
    }
    return Math.round(numericPrice * 100);
  }

  console.error("Unexpected price format:", priceString);
  return 0; // Default to 0 for unexpected formats
};

const BookingPage = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState(new Set());
  const [unavailableCheckOutDates, setUnavailableCheckOutDates] = useState(new Set());
  const [defaultRates, setDefaultRates] = useState([]);
  const [confirmedRates, setConfirmedRates] = useState([]);
  const [salesChannelId, setSalesChannelId] = useState(null);
  const navigate = useNavigate();
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const formRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [maxOccupancy, setMaxOccupancy] = useState(null);
    const location = useLocation();
  
  const rooms = [
    { id: 64822, title: 'Double Room', img: doubleRoom, occupancy: 2 },
    { id: 64821, title: 'Double Room with Balcony', img: doubleBalcony, occupancy: 2 },
    { id: 64820, title: t("Double Room with Balcony (Pet Friendly)"), img: doubleBalconyPet, occupancy: 2 },
    { id: 64819, title: 'Quad Room', img: quadRoom, occupancy: 4 },
    { id: 64818, title: t("Double / Twin Room with Balcony"), img: twinRoom, occupancy: 2 },
  ];

  const [bookingData, setBookingData] = useState({
    selectedRoom: null,
    checkIn: "",
    checkOut: "",
    guests: 1,
    children: 0,
    rooms: 1,
    fullName: "",
    email: "",
    note: "",
    cardHolder: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
  });

  const handleBookingSuccess = (data) => {
    console.log("Booking successful!", data);
    setBookingSuccess(true);
  };

  useEffect(() => {
    if (formRef.current) {
      setTimeout(() => {
        formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, [step]);
     
  useEffect(() => {
    const fetchAvailability = async () => {
      if (!selectedUnitType) return;

      const today = new Date();
      const startDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-01`;
      const endDate = `${today.getFullYear() + 2}-12-31`;

      try {
        const response = await fetch(
          `https://danda.hr/proxy.php?endpoint=unit-types-availability&unitTypeId=${selectedUnitType}&dateFrom=${startDate}&dateTo=${endDate}`
        );
        const result = await response.json();

        const unavailable = new Set();
        const checkOutUnavailable = new Set();

        result.data.forEach((item) => {
          if (item.availability === 0) {
            unavailable.add(item.date);
          }
        });

        if (unavailable.size > 0) {
          const sortedUnavailable = Array.from(unavailable).sort();
          for (let i = 0; i < sortedUnavailable.length; i++) {
            const unavailableDate = new Date(sortedUnavailable[i]);
            unavailableDate.setDate(unavailableDate.getDate() + 1);
  
            // Add only if it's not the first unavailable date after today
            if (i === 0) {
              // Allow the first unavailable date as a valid check-out date
              continue;
            }
            checkOutUnavailable.add(unavailableDate.toISOString().split("T")[0]);
          }
        }  

        setUnavailableDates(unavailable);
        setUnavailableCheckOutDates(checkOutUnavailable);
      } catch (err) {
        console.error("Error fetching availability:", err);
      }
    };

    const fetchDefaultRates = async () => {
      if (!selectedUnitType) return;
  
      const today = new Date();
      const sixMonthsLater = new Date(today.getFullYear(), today.getMonth() + 6, 0);
  
      try {
        const response = await fetch(
          `https://danda.hr/proxy.php?endpoint=unit-types-rates&unitTypeId=${selectedUnitType}&dateFrom=${today.toISOString().split("T")[0]}&dateTo=${sixMonthsLater.toISOString().split("T")[0]}`
        );
        const result = await response.json();
        setDefaultRates(result.data || []);
      } catch (err) {
        console.error("Error fetching default rates:", err);
      }
    };
  
    // Call both fetch functions
    fetchAvailability();
    fetchDefaultRates();
  }, [selectedUnitType]);

  useEffect(() => {
    let isCancelled = false;

    fetch("https://danda.hr/proxy.php?endpoint=properties/31697/sales-channels")
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch sales channels");
        return response.json();
      })
      .then((salesData) => {
        if (isCancelled) return;

        if (salesData.data?.length > 0) {
          setSalesChannelId(salesData.data[0]?.id);
        }
      })
      .catch((err) => {
        console.error("Error fetching sales channels:", err.message);
      });

    return () => {
      isCancelled = true;
    };
}, []);

  const handleBookingChange = async (e) => {
    const { name, value } = e.target;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split("T")[0];
    };

    const isRangeValid = (start, end) => {
      const currentDate = new Date(start);
      const endDate = new Date(end);

      let unavailableCount = 0;

      while (currentDate <= endDate) {
          const formattedDate = formatDate(currentDate.toISOString());
          if (unavailableDates.has(formattedDate)) {
              unavailableCount++;
              // Allow the first unavailable date, but reject if more than 1
              if (unavailableCount > 1) {
                  return false;
              }
          }
          currentDate.setDate(currentDate.getDate() + 1);
      }

      return true; // Range is valid if there is at most 1 unavailable date
  };

    if (name === "checkIn") {
        // Prevent selecting an unavailable check-in date
        if (unavailableDates.has(value)) {
            setAlertMessage(t("The selected check-in date is already booked. Please choose another date."));
            return;
        }
        // Clear check-out if it is earlier than or equal to the new check-in
        if (bookingData.checkOut && new Date(value) >= new Date(bookingData.checkOut)) {
            setBookingData({ ...bookingData, checkOut: "" });
        }
    }

    if (name === "checkOut") {
        const checkIn = bookingData.checkIn;
        const checkOut = value;

        // Ensure the selected check-out is after the check-in
        if (new Date(checkOut) <= new Date(checkIn)) {
            setAlertMessage(t("Check-out date must be after the check-in date."));
            return;
        }

        // Adjusted range validation: allow the first unavailable date but no more
        if (checkIn && !isRangeValid(checkIn, checkOut)) {
          setAlertMessage(t("Selected dates include unavailable dates. Please choose another date of departure."));
          return;
      }
    }

    if (name === "checkIn" || name === "checkOut") {
        const checkIn = name === "checkIn" ? value : bookingData.checkIn;
        const checkOut = name === "checkOut" ? value : bookingData.checkOut;

        if (checkIn && checkOut) {
            // Fetch rates for the selected range
            try {
                const response = await fetch(
                    `https://danda.hr/proxy.php?endpoint=unit-types-rates&unitTypeId=${selectedUnitType}&dateFrom=${checkIn}&dateTo=${checkOut}`
                );
                const result = await response.json();
                setConfirmedRates(result.data || []);
            } catch (err) {
                console.error("Error fetching confirmed rates:", err);
                setAlertMessage("Failed to fetch rates for the selected date range.");
            }
        }
    }

    setAlertMessage(null); // Clear alert messages for valid cases
    setBookingData({ ...bookingData, [name]: value });
};

const handleUnitTypeChange = (unitTypeId) => {
  setSelectedUnitType(unitTypeId); // Update selected unit

  fetch(`https://danda.hr/proxy.php?endpoint=properties/31697/unit-types`)
    .then((response) => {
      if (!response.ok) throw new Error("Failed to fetch unit type details");
      return response.json();
    })
    .then((data) => {

      // Find the selected unit details
      const selectedUnitDetails = data.data.find(
        (unit) => unit.id === parseInt(unitTypeId, 10)
      );

      if (selectedUnitDetails) {
        setMaxOccupancy(selectedUnitDetails.maxOccupancy); // Update max occupancy
        setBookingData((prevData) => ({
          ...prevData,
          adults: 1, // Reset guests to a valid default
          children: 0,
        }));
      } else {
        console.error("Selected unit details not found in fetched data.");
      }
    })
    .catch((error) => {
      console.error("Error fetching unit type details:", error.message);
    });
};

const handleBookingSubmit = (paymentIntentId) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const childrenArray = (bookingData.childAges || []).map((age) => ({ age: parseInt(age, 10) || 0 }));

  // Calculate total guests (adults + children)
  const totalGuests = parseInt(bookingData.guests, 10) + childrenArray.length;

const bookingPayload = {
  unitTypeId: parseInt(selectedUnitType, 10),
  dateFrom: formatDate(bookingData.checkIn),
  dateTo: formatDate(bookingData.checkOut),
  email: bookingData.email.trim(),
  fullName: bookingData.fullName.trim(),
  note: bookingData.note.trim(),
  persons: totalGuests,
  adults: parseInt(bookingData.guests, 10), 
  children: childrenArray, 
  rooms: 1,
  salesChannelsId: parseInt(salesChannelId, 10),
  paymentIntentId,
};
fetch("https://danda.hr/proxy.php?endpoint=reservations", {
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(bookingPayload),
})
  .then((response) => {
    if (!response.ok) throw new Error("Failed to submit booking");
    return response.json();
  })
  .then((data) => {
    setAlertMessage(t("🎉 Booking successful! Your reservation has been confirmed. A confirmation email will arrive shortly. Thank you for choosing us!"));
    setBookingSuccess(true);
    
    setStep(1);
    setBookingData({
      checkIn: "",
      checkOut: "",
      guests: 1,
      children: 0,
      childAges: [],
      rooms: 1,
      fullName: "",
      email: "",
      note: "",
      cardHolder: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
    });
    setTimeout(() => {
      navigate("/");
    }, 3000);
  })
  .catch((err) => {
    setAlertMessage(`Error: Unable to complete your booking. ${err.message}`);
  });
};

  const calculateTotalAmount = () => {
    if (!bookingData.checkIn || !bookingData.checkOut || confirmedRates.length === 0) {
        return { totalAmount: 0, numberOfNights: 0, pricePerNight: 0 };
    }

    const checkInDate = new Date(bookingData.checkIn);
    const checkOutDate = new Date(bookingData.checkOut);
    const numberOfNights = Math.ceil((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));

    const pricePerNight =
        confirmedRates.length > 0
            ? confirmedRates[0]?.price
            : defaultRates.length > 0
            ? defaultRates[0]?.price
            : 0;

    const totalAmount = numberOfNights * pricePerNight;

    return { 
        totalAmount: convertPriceToCents(totalAmount), 
        numberOfNights, 
        pricePerNight 
    };
};

const handleNextStep = () => {
  if (step === 1) {
      // Step 1: Validate Room Selection & Dates
      if (!selectedUnitType) {
          setAlertMessage(t("Please select a room before proceeding."));
          return;
      }

      if (!bookingData.checkIn || !bookingData.checkOut) {
          setAlertMessage(t("Please select both check-in and check-out dates."));
          return;
      }

      const checkInDate = new Date(bookingData.checkIn);
      const checkOutDate = new Date(bookingData.checkOut);

      if (checkOutDate <= checkInDate) {
          setAlertMessage(t("Check-out date must be after the check-in date."));
          return;
      }
  } 

  else if (step === 2) {

    if (!bookingData.fullName || !bookingData.email || !bookingData.note || bookingData.guests === 0) {
        setAlertMessage(t("Please fill out all required fields before proceeding."));
        return;
    }
    if (!/\S+@\S+\.\S+/.test(bookingData.email)) {
      setAlertMessage(t("Please enter a valid email address."));
      return;
  }

  if (!/^\+?\d+$/.test(bookingData.note)) {
    setAlertMessage(t("Please enter a valid phone number."));
    return;
  }
}

  else if (step === 3) {
  }

  setAlertMessage("");
  setStep((prevStep) => prevStep + 1);
};

const handlePreviousStep = () => {
  if (step > 1) {
      setStep((prevStep) => prevStep - 1);
  }
};

  return (
    <div className="booking-page-container">
      {alertMessage && <CustomAlert message={alertMessage} onClose={() => setAlertMessage("")} />}
      <Helmet>
        <title>{t('Reserve Your Stay | Hotel Natura')}</title>
        <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
        <meta name="description" content={t('Book your stay quickly and easily.')} />
      </Helmet>
  
      <div className="booking-form-wrapper">
        <div ref={formRef} className="booking-hero-image">
          <h1>{t('Relax & Enjoy Your Stay')}</h1>
          <p className="helper-text">
            {t("Secure your stay in just a few easy steps. Select your room, provide guest details, and finalize your reservation with a seamless payment process.")}
          </p>
        </div>
  
        <div className="booking-content">
        <div className="booking-progress">
        <div className={`progress-step ${step >= 1 ? "active completed" : ""}`}>
  <div className="progress-circle">
    {step > 1 ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCalendarDays} />}
  </div>
  <span>{t("Rooms & Dates")}</span>
</div>

<div className={`progress-step ${step >= 2 ? "active completed" : ""}`}>
  <div className="progress-circle">
    {step > 2 ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faUserFriends} />}
  </div>
  <span>{t("Guest Information")}</span>
</div>

<div className={`progress-step ${step >= 3 ? "active completed" : ""}`}>
  <div className="progress-circle">
    {step > 3 ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faClipboardCheck} />}
  </div>
  <span>{t("Review & Proceed")}</span>
</div>

<div className={`progress-step ${step >= 4 ? "active completed" : ""}`}>
  <div className="progress-circle">
    {step > 4 ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCreditCard} />}
  </div>
  <span>{t("Payment")}</span>
</div>
</div>
  
{step === 1 && ( 
  <div className="step step-1">
    <h2>
      <FontAwesomeIcon icon={faCalendarDays} className="step-icon" /> {t("Choose Room & Dates")}
    </h2>
    <p className="step-helper-text">
      {t("Click on a room to select it, then choose your check-in and check-out dates before proceeding.")}
    </p>

    <div className="room-selection-cards">
      {rooms.map((room) => (
        <div
          key={room.id}
          className={`room-card ${bookingData.selectedRoom === room.id ? "selected" : ""}`}
          onClick={() => {
            setBookingData({ ...bookingData, selectedRoom: room.id, checkIn: "", checkOut: "" });
            setSelectedUnitType(room.id);
            handleUnitTypeChange(room.id);
          }}
        >
          <img src={room.img} alt={room.title} />
          <div className="room-card-overlay">
            <div className="room-info">
              <h3>{t(room.title)}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>

    <div className="date-picker-row">
  <div className="date-picker-group">
    <label>{t("Check-In")}</label>
    <p className="step-helper-text">{t("Select the start date of your stay. Unavailable dates are disabled.")}</p>
    <div className="input-with-icon">
      <DatePicker
        selected={bookingData.checkIn ? new Date(bookingData.checkIn) : null}
        onChange={(date) => {
          const normalizedDate = date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null;
          handleBookingChange({
            target: { name: "checkIn", value: normalizedDate ? normalizedDate.toISOString().split("T")[0] : "" },
          });
        }}
        onMonthChange={() => setBookingData((prev) => ({ ...prev, checkIn: "" }))}
        placeholderText={t("Select check-in date")}
        minDate={new Date()} 
        excludeDates={[...unavailableDates].map((date) => new Date(date))}
        dateFormat="dd/MM/yyyy"
        calendarStartDay={1}
        calendarClassName="check-in-calendar"
      />
      <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
    </div>
  </div>

  <div className="date-picker-group">
    <label>{t("Check-Out")}</label>
    <p className="step-helper-text">{t("Select your departure date. Ensure it's after the check-in date.")}</p>
    <div className="input-with-icon">
      <DatePicker
        selected={bookingData.checkOut ? new Date(bookingData.checkOut) : null}
        onChange={(date) => {
          const normalizedDate = date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null;
          handleBookingChange({
            target: { name: "checkOut", value: normalizedDate ? normalizedDate.toISOString().split("T")[0] : "" },
          });
        }}
        onMonthChange={() => setBookingData((prev) => ({ ...prev, checkOut: "" }))}
        placeholderText={t("Select check-out date")}
        minDate={new Date(bookingData.checkIn)}
        excludeDates={[...unavailableCheckOutDates].map((date) => new Date(date))}
        dateFormat="dd/MM/yyyy"
        calendarStartDay={1}
        calendarClassName="check-out-calendar"
      />
      <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
    </div>
  </div>
</div>


    {bookingData.selectedRoom && bookingData.checkIn && bookingData.checkOut && (
  <div className="price-summary">
    <h3 className="summary-title">{t("Price Summary")}</h3>
    
    <div className="summary-details">
      <div className="summary-item">
        <span>{t("Nights")}:</span>
        <span>{calculateTotalAmount().numberOfNights}</span>
      </div>
      <div className="summary-item">
        <span>{t("Price per Night")}:</span>
        <span>€{calculateTotalAmount().pricePerNight.toFixed(2)}</span>
      </div>
      
      <hr className="summary-divider" />
      
      <div className="summary-item total">
        <span>{t("Total Amount")}:</span>
        <span>€{(calculateTotalAmount().totalAmount / 100).toFixed(2)}</span>
      </div>
    </div>
  </div>
)}


    <button className="next-btn" onClick={handleNextStep}>{t('Next')}</button>
  </div>
)}

{step === 2 && (
  <div className="step step-2">
    <h2>
      <FontAwesomeIcon icon={faUserFriends} className="step-icon" /> {t("Guest Information")}
    </h2>
    <p className="step-helper-text">
      {t("Enter your personal details and specify the number of guests.")}
    </p>

    <div className="input-row">
      <div className="input-group">
        <label>{t('Full Name')}</label>
        <p className="step-helper-text">
          {t("Enter your full name as it appears on your identification.")}
        </p>
        <input
          type="text"
          value={bookingData.fullName}
          onChange={(e) => setBookingData({ ...bookingData, fullName: e.target.value })}
          required
        />
      </div>

      <div className="input-group">
        <label>{t('Email')}</label>
        <p className="step-helper-text">
          {t("Enter a valid email address.")}
        </p>
        <input
          type="email"
          value={bookingData.email}
          onChange={(e) => setBookingData({ ...bookingData, email: e.target.value })}
          required
        />
      </div>
    </div>

    <div className="input-group">
      <label>{t('Phone Number')}</label>
      <p className="step-helper-text">
        {t("Enter a phone number where we can reach you if needed.")}
      </p>
      <input
        type="text"
        name="note"
        value={bookingData.note}
        onChange={(e) => setBookingData({ ...bookingData, note: e.target.value })}
        required
      />
    </div>

    <div className="input-row">
      <div className="input-group">
        <label>{t('Adults')}</label>
        <p className="step-helper-text">{t("Enter the total number of adults.")}</p>
        <input
          type="number"
          min="1"
          max={maxOccupancy - (bookingData.children || 0)} 
          value={bookingData.adults}
          onChange={(e) => {
            const numAdults = parseInt(e.target.value, 10) || 1;
            if (numAdults + (bookingData.children || 0) <= maxOccupancy) {
              setBookingData({ ...bookingData, adults: numAdults });
            }
          }}
          required
        />
      </div>

      <div className="input-group">
        <label>{t('Children')}</label>
        <p className="step-helper-text">{t("Enter the number of children (if any).")}</p>
        <input
          type="number"
          min="0"
          max={maxOccupancy - (bookingData.adults || 1)}
          value={bookingData.children}
          onChange={(e) => {
            const numChildren = parseInt(e.target.value, 10) || 0;
            if (numChildren + (bookingData.adults || 1) <= maxOccupancy) {
              setBookingData({
                ...bookingData,
                children: numChildren,
                childrenAges: Array(numChildren).fill(5), 
              });
            }
          }}
        />
      </div>
    </div>

    {bookingData.children > 0 && (
      <div className="children-age-group">
        <label>{t("Children's Ages")}</label>
        <p className="step-helper-text">
          {t("Enter the age of each child (max 17 years).")}
        </p>
        <div className="child-age-container">
          {bookingData.childrenAges.map((age, index) => (
            <input
              key={index}
              type="number"
              min="0"
              max="17"
              className="child-age-input"
              value={age}
              onChange={(e) => {
                const newAges = [...bookingData.childrenAges];
                newAges[index] = parseInt(e.target.value, 10) || 0;
                setBookingData({ ...bookingData, childrenAges: newAges });
              }}
              placeholder={t(`Child ${index + 1} Age`)}
              required
            />
          ))}
        </div>
      </div>
    )}

    <div className="step-navigation">
      <button className="back-btn" onClick={handlePreviousStep}>{t('Back')}</button>
      <button className="next-btn" onClick={handleNextStep}>{t('Next')}</button>
    </div>
  </div>
)}

{step === 3 && (
  <div className="step step-3">
    <h2>
      <FontAwesomeIcon icon={faClipboardCheck} className="step-icon" /> {t("Review & Proceed")}
    </h2>
    <p className="step-helper-text">
      {t("Check your details below before proceeding to payment.")}
    </p>

    <div className="booking-summary">
      <p><strong>{t('Room')}:</strong> {rooms.find(room => room.id === bookingData.selectedRoom)?.title || t("Not selected")}</p>
      <p><strong>{t('Guest Name')}:</strong> {bookingData.fullName}</p>
      <p><strong>{t('Check-In')}:</strong> {bookingData.checkIn ? new Date(bookingData.checkIn).toLocaleDateString() : t("Not selected")}</p>
      <p><strong>{t('Email')}:</strong> {bookingData.email}</p>
      <p><strong>{t('Check-Out')}:</strong> {bookingData.checkOut ? new Date(bookingData.checkOut).toLocaleDateString() : t("Not selected")}</p>
      <p><strong>{t('Phone')}:</strong> {bookingData.note}</p>
      <p><strong>{t('Adults')}:</strong> {bookingData.adults}</p>
      <p><strong>{t('Children')}:</strong> {bookingData.children}</p>
      {bookingData.children > 0 && (
        <div className="children-age-summary">
          <p><strong>{t("Children's Ages")}:</strong> {bookingData.childrenAges.join(', ')}</p>
        </div>
      )}
<div className="total-price-container">
  <p className="total-price">
    {t('Total Price')}: €{(calculateTotalAmount().totalAmount / 100).toFixed(2)}
  </p>
</div>
    </div>
    <div className="step-navigation">
      <button className="back-btn" onClick={handlePreviousStep}>{t('Back')}</button>
      <button className="confirm-btn" onClick={() => setStep(4)}>
        {t('Proceed to Payment')}
      </button>
    </div>
  </div>
)}

{step === 4 && (
  <div className="step-4-container">
    <StripeProvider>
      <PaymentForm
        amount={calculateTotalAmount().totalAmount}
        currency="eur"
        onPaymentSuccess={(paymentIntentId) => {
          setAlertMessage(t("Payment successful!"));
          handleBookingSubmit(paymentIntentId);
        }}
        onPaymentError={(error) => setAlertMessage(t(`Payment failed: ${error}`))}
      />
    </StripeProvider>
    <div className="step-navigation4">
      <button className="back-btn4" onClick={() => setStep(3)}>
        {t("Back")}
      </button>
    </div>
  </div>
)}
      </div>
    </div>
    </div>
  );
  
};

export default BookingPage;