import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./EmailSubscription.css";

const EmailSubscription = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "" });

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/send_subscription.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ email }),
      });

      const result = await response.text();

      if (result === "success") {
        setAlert({ message: t("Thank you! We'll contact you soon."), type: "success" });
        setEmail("");
      } else {
        setAlert({ message: t("There was an error. Please try again."), type: "error" });
      }
    } catch {
      setAlert({ message: t("An unexpected error occurred."), type: "error" });
    }

    setTimeout(() => setAlert({ message: "", type: "" }), 5000);
  };

  return (
    <div className="email-subscription">
      <div className="overlay"></div>
      <h2 className="subscription-heading">
        {t("SHARE YOUR EMAIL WITH US, AND WE'LL BE SURE TO GET IN TOUCH WITH YOU")}
      </h2>
      {alert.message && (
        <div className={`alert ${alert.type}`}>
          {alert.message}
        </div>
      )}
      <form className="subscription-form" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder={t("YOUR EMAIL")}
          value={email}
          onChange={handleChange}
          className="email-input"
          required
        />
        <button type="submit" className="submit-button">
          {t("SEND")}
        </button>
      </form>
    </div>
  );
};

export default EmailSubscription;