import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./RoomsCarousel.css";
import doubleRoom from "../assets/rooms/doubleroom.webp";
import doubleRoomBalcony from "../assets/rooms/quad_room.webp";
import twinRoom from "../assets/rooms/twinroom.webp";
import doubleRoomBalconyPet from "../assets/rooms/double_balcony_petfr.webp";
import prevIcon from "../assets/icons/previous.png";
import nextIcon from "../assets/icons/next.png";
import { useTranslation } from "react-i18next";

const RoomsCarousel = () => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [animationClass, setAnimationClass] = useState("");
    const navigate = useNavigate();
    const autoScrollRef = useRef(null);

    const rooms = [
        { image: doubleRoom, title: t("Double Room"), description: t("Double bed"), link: "/booking" },
        { image: twinRoom, title: t("Twin Room"), description: t("Two single beds"), link: "/booking" },
        { image: doubleRoomBalcony, title: t("Double Room with Balcony"), description: t("Double bed with balcony"), link: "/booking" },
        { image: doubleRoomBalconyPet, title: t("Double Room with Balcony (Pet Friendly)"), description: t("Pet Friendly Double bed with balcony"), link: "/booking" },
        { image: doubleRoomBalcony, title: t("Quad Room"), description: t("Two connected Double Rooms"), link: "/booking" }
    ];

    const totalRooms = rooms.length;

    const getVisibleRooms = () => [
        rooms[currentIndex % totalRooms],
        rooms[(currentIndex + 1) % totalRooms]
    ];

    const resetAutoScroll = () => {
        if (autoScrollRef.current) {
            clearInterval(autoScrollRef.current);
        }
        autoScrollRef.current = setInterval(() => {
            changeSlide("next");
        }, 10000);
    };

    const changeSlide = (direction) => {
        if (isSliding) return;
    
        setIsSliding(true);
        setAnimationClass(direction === "next" ? "slide-out-left" : "slide-out-right");
    
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                direction === "next"
                    ? (prevIndex + 1) % totalRooms
                    : (prevIndex - 1 + totalRooms) % totalRooms
            );
            setAnimationClass(direction === "next" ? "slide-in-left" : "slide-in-right");
    
            setTimeout(() => {
                setAnimationClass("");
                setIsSliding(false);
            }, 400);
        }, 300);
    
        resetAutoScroll();
    };

    useEffect(() => {
        resetAutoScroll();
        return () => clearInterval(autoScrollRef.current);
    }, []);

    return (
        <div className="rooms-carousel">
            <div className={`carousel-container ${animationClass}`}>
                {getVisibleRooms().map((room, index) => (
                    <div
                        key={index}
                        className="carousel-item"
                        onClick={() => navigate(room.link)}
                    >
                        <img src={room.image} alt={room.title} />
                        <div className="room-overlay">
                            <p className="room-title">{room.title}</p>
                            <p className="room-description">{room.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="carousel-btn-container">
    <button className="carousel-btn left" onClick={() => changeSlide("prev")}>
        <img src={prevIcon} alt="Previous" />
    </button>
    <button className="carousel-btn right" onClick={() => changeSlide("next")}>
        <img src={nextIcon} alt="Next" />
    </button>
</div>
        </div>
    );
};

export default RoomsCarousel;