import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./GalleryPage.css";
import DividerIcon from "../assets/icons/divider.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import outside1 from "../assets/gallery/outside/natura_outside1.webp";
import outside2 from "../assets/gallery/outside/natura_outside2.webp";
import outside3 from "../assets/gallery/outside/natura_outside3.webp";
import outside4 from "../assets/gallery/outside/natura_outside4.webp";
import outside5 from "../assets/gallery/outside/natura_outside5.webp";
import outside6 from "../assets/gallery/outside/natura_outside6.webp";
import outside7 from "../assets/gallery/outside/natura_outside7.webp";
import outside8 from "../assets/gallery/outside/natura_outside8.webp";
import outside9 from "../assets/gallery/outside/natura_outside9.webp";
import outside10 from "../assets/gallery/outside/natura_outside10.webp";
import outside11 from "../assets/gallery/outside/natura_outside11.webp";
import outside12 from "../assets/gallery/outside/natura_outside12.webp";
import outside13 from "../assets/gallery/outside/natura_outside13.webp";
import outside14 from "../assets/gallery/outside/natura_outside14.webp";
import outside15 from "../assets/gallery/outside/natura_outside15.webp";
import outside16 from "../assets/gallery/outside/natura_outside16.webp";
import outside17 from "../assets/gallery/outside/natura_outside17.webp";
import outside18 from "../assets/gallery/outside/natura_outside18.webp";
import outside19 from "../assets/gallery/outside/natura_outside19.webp";
import outside20 from "../assets/gallery/outside/natura_outside20.webp";

import inside1 from "../assets/gallery/inside/natura_rooms1.webp";
import inside2 from "../assets/gallery/inside/natura_rooms2.webp";
import inside3 from "../assets/gallery/inside/natura_rooms3.webp";
import inside4 from "../assets/gallery/inside/natura_rooms4.webp";
import inside5 from "../assets/gallery/inside/natura_rooms5.webp";
import inside6 from "../assets/gallery/inside/natura_rooms6.webp";
import inside7 from "../assets/gallery/inside/natura_rooms7.webp";
import inside8 from "../assets/gallery/inside/natura_rooms8.webp";
import inside9 from "../assets/gallery/inside/natura_rooms9.webp";
import inside10 from "../assets/gallery/inside/natura_rooms10.webp";
import inside11 from "../assets/gallery/inside/natura_rooms11.webp";
import inside12 from "../assets/gallery/inside/natura_rooms12.webp";
import inside13 from "../assets/gallery/inside/natura_rooms13.webp";
import inside14 from "../assets/gallery/inside/natura_rooms14.webp";
import inside15 from "../assets/gallery/inside/natura_rooms15.webp";
import inside16 from "../assets/gallery/inside/natura_rooms16.webp";

import food1 from "../assets/gallery/food/natura_breakfast1.webp";
import food2 from "../assets/gallery/food/natura_breakfast2.webp";
import food3 from "../assets/gallery/food/natura_breakfast3.webp";
import food4 from "../assets/gallery/food/natura_breakfast4.webp";
import food5 from "../assets/gallery/food/natura_breakfast5.webp";
import food6 from "../assets/gallery/food/natura_breakfast6.webp";
import food7 from "../assets/gallery/food/natura_breakfast7.webp";
import food8 from "../assets/gallery/food/natura_breakfast8.webp";
import food9 from "../assets/gallery/food/natura_breakfast9.webp";
import food10 from "../assets/gallery/food/natura_breakfast10.webp";
import food11 from "../assets/gallery/food/natura_breakfast11.webp";
import food12 from "../assets/gallery/food/natura_breakfast12.webp";
import food13 from "../assets/gallery/food/natura_breakfast13.webp";
import food14 from "../assets/gallery/food/natura_breakfast14.webp";
import food15 from "../assets/gallery/food/natura_breakfast15.webp";
import food16 from "../assets/gallery/food/natura_breakfast16.webp";

import pool1 from "../assets/gallery/pool/natura_pool1.webp";
import pool2 from "../assets/gallery/pool/natura_pool2.webp";
import pool3 from "../assets/gallery/pool/natura_pool3.webp";
import pool4 from "../assets/gallery/pool/natura_pool4.webp";
import pool5 from "../assets/gallery/pool/natura_pool5.webp";
import pool6 from "../assets/gallery/pool/natura_pool6.webp";
import pool7 from "../assets/gallery/pool/natura_pool7.webp";
import pool8 from "../assets/gallery/pool/natura_pool8.webp";
import pool9 from "../assets/gallery/pool/natura_pool9.webp";
import pool10 from "../assets/gallery/pool/natura_pool10.webp";
import pool11 from "../assets/gallery/pool/natura_pool11.webp";
import pool12 from "../assets/gallery/pool/natura_pool12.webp";
import pool13 from "../assets/gallery/pool/natura_pool13.webp";
import pool14 from "../assets/gallery/pool/natura_pool14.webp";
import pool15 from "../assets/gallery/pool/natura_pool15.webp";
import pool16 from "../assets/gallery/pool/natura_pool16.webp";

import wellness1 from "../assets/gallery/wellness/natura_wellness1.webp";
import wellness2 from "../assets/gallery/wellness/natura_wellness2.webp";
import wellness3 from "../assets/gallery/wellness/natura_wellness3.webp";
import wellness4 from "../assets/gallery/wellness/natura_wellness4.webp";
import wellness5 from "../assets/gallery/wellness/natura_wellness5.webp";
import wellness6 from "../assets/gallery/wellness/natura_wellness6.webp";
import wellness7 from "../assets/gallery/wellness/natura_wellness7.webp";
import wellness8 from "../assets/gallery/wellness/natura_wellness8.webp";

import staff1 from "../assets/gallery/staff/natura_staff1.webp";
import staff2 from "../assets/gallery/staff/natura_staff2.webp";
import staff3 from "../assets/gallery/staff/natura_staff3.webp";
import staff4 from "../assets/gallery/staff/natura_staff4.webp";
import staff5 from "../assets/gallery/staff/natura_staff5.webp";
import staff6 from "../assets/gallery/staff/natura_staff6.webp";
import staff7 from "../assets/gallery/staff/natura_staff7.webp";
import staff8 from "../assets/gallery/staff/natura_staff8.webp";

import night1 from "../assets/gallery/night/natura_night1.webp";
import night2 from "../assets/gallery/night/natura_night2.webp";
import night3 from "../assets/gallery/night/natura_night3.webp";
import night4 from "../assets/gallery/night/natura_night4.webp";
import night5 from "../assets/gallery/night/natura_night5.webp";
import night6 from "../assets/gallery/night/natura_night6.webp";
import night7 from "../assets/gallery/night/natura_night7.webp";
import night8 from "../assets/gallery/night/natura_night8.webp";
import night9 from "../assets/gallery/night/natura_night9.webp";
import night10 from "../assets/gallery/night/natura_night10.webp";
import night11 from "../assets/gallery/night/natura_night11.webp";
import night12 from "../assets/gallery/night/natura_night12.webp";
import night13 from "../assets/gallery/night/natura_night13.webp";
import night14 from "../assets/gallery/night/natura_night14.webp";
import night15 from "../assets/gallery/night/natura_night15.webp";
import night16 from "../assets/gallery/night/natura_night16.webp";

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const { t } = useTranslation();
  const location = useLocation();

  const categories = {
    "Exterior": [outside1, outside2, outside3, outside4, outside5, outside6, outside7, outside8, outside9, outside10, outside11, outside12, outside13, outside14, outside15, outside16, outside17, outside18, outside19, outside20],
    "Interior": [food1, food2, food3, food4, food5, food6, food7, food8, food9, food10, food11, food12, food13, food14, food15, food16],
    "Rooms": [inside1, inside2, inside3, inside4, inside5, inside6, inside7, inside8, inside9, inside10, inside11, inside12, inside13, inside14, inside15, inside16],
    "Pool": [pool1, pool2, pool3, pool4, pool5, pool6, pool7, pool8, pool9, pool10, pool11, pool12, pool13, pool14, pool15, pool16],
    "Wellness": [wellness1, wellness2, wellness3, wellness4, wellness5, wellness6, wellness7, wellness8],
    "By Night": [night1, night2, night3, night4, night5, night6, night7, night8, night9, night10, night11, night12, night13, night14, night15, night16],
    "Staff": [staff1, staff2, staff3, staff4, staff5, staff6, staff7, staff8],
  };

  const openModal = (images, index) => {
    setImageList(images);
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };

  const showPreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length
    );
  };

  return (
    <div className="gallery-container">
      <Helmet>
        <title>{t("Gallery | Hotel Natura")}</title>
        <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
        <meta
          name="description"
          content={t(
            "Explore the photo gallery of Hotel Natura and discover our beautiful rooms, amenities, and surroundings."
          )}
        />
      </Helmet>
      <div className="gallery-header">
        <img src={DividerIcon} alt="Divider" className="divider-icon" />
        <h2 className="gallery-heading">{t("Explore Our Spaces")}</h2>
      </div>

      <p className="gallery-subtext">
        {t("A glimpse into our beautiful surroundings, cozy interiors, and delicious cuisine.")}
      </p>

      {Object.entries(categories).map(([category, images], index) => (
        <div key={category}>
          {index !== 0 && <hr className="gallery-divider" />}
          <h3 className="gallery-category-title">{t(category)}</h3>
          <div className="gallery-grid">
  {images.map((src, idx) => (
    <div key={idx} className="gallery-image-container">
      <img
        src={src}
        alt={category}
        className="gallery-image"
        loading="lazy"
        onClick={() => openModal(images, idx)}
      />
    </div>
  ))}
</div>
        </div>
      ))}

      {isModalOpen && (
        <div className="image-modal-overlay">
          <button className="close-modal" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button className="prev-arrow" onClick={showPreviousImage}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <img
            src={imageList[currentImageIndex]}
            alt={`Gallery Image ${currentImageIndex + 1}`}
            className="modal-image"
          />
          <button className="next-arrow" onClick={showNextImage}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Gallery;