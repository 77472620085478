import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import RoomsPage from "./pages/RoomsPage";
import ContactPage from "./pages/ContactPage";
import GalleryPage from "./pages/GalleryPage";
import BookingPage from "./pages/BookingPage";
import TermsPage from "./pages/TermsOfUse";
import ComplaintPage from "./pages/Complaint";
import PrivacyPage from "./pages/PrivacyPolicy";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import PrivateHire from "./pages/PrivateHire";
import "./i18n";
import "./App.css";

const LanguageWrapper = ({ children }) => {
    const { i18n } = useTranslation();
    const location = useLocation();
    
    useEffect(() => {
        const langFromURL = location.pathname.split("/")[1];
        if (["en", "hr", "it", "de"].includes(langFromURL)) {
            i18n.changeLanguage(langFromURL);
        } else {
            i18n.changeLanguage("en");
        }
    }, [location, i18n]);

    return children;
};

function App() {
    return (
        <Router>
            <LanguageWrapper>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about-us/" element={<AboutPage />} />
                    <Route path="/rooms/" element={<RoomsPage />} />
                    <Route path="/gallery/" element={<GalleryPage />} />
                    <Route path="/private-hire/" element={<PrivateHire />} />
                    <Route path="/contact-us/" element={<ContactPage />} />
                    <Route path="/booking/" element={<BookingPage />} />
                    <Route path="/terms-of-use/" element={<TermsPage />} />
                    <Route path="/how-to-submit-a-written-complaint/" element={<ComplaintPage />} />
                    <Route path="/privacy-policy/" element={<PrivacyPage />} />

                    <Route path="/:lang/" element={<HomePage />} />
                    <Route path="/:lang/about-us/" element={<AboutPage />} />
                    <Route path="/:lang/rooms/" element={<RoomsPage />} />
                    <Route path="/:lang/gallery/" element={<GalleryPage />} />
                    <Route path="/:lang/private-hire/" element={<PrivateHire />} />
                    <Route path="/:lang/contact-us/" element={<ContactPage />} />
                    <Route path="/:lang/booking/" element={<BookingPage />} />
                    <Route path="/:lang/terms-of-use/" element={<TermsPage />} />
                    <Route path="/:lang/how-to-submit-a-written-complaint/" element={<ComplaintPage />} />
                    <Route path="/:lang/privacy-policy/" element={<PrivacyPage />} />

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer />
            </LanguageWrapper>
        </Router>
    );
}

export default App;