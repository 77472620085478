import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
  
  return (
    <div className="privacy-container">
      <Helmet>
        <title>{t("Privacy Policy | Hotel Natura")}</title>
        <link rel="canonical" href="https://hotel-natura.com/privacy-policy/" />
        <meta
          name="description"
          content={t(
            "Read the Privacy Policy of Hotel Natura, explaining how we collect, handle, and protect your personal information."
          )}
        />
      </Helmet>
      <div className="privacy-content">
        <h1 className="privacy-title">PRIVACY POLICY</h1>
        <p>Please read the following text carefully before using our Website.</p>
        <p>
          This Privacy Statement applies to the confidentiality of personal data collected in the process of registering Customers of the hotel-natura.hr Website or in the process of registering Users of recipients of product and promotions (newsletter) Website hotel-natura.hr.
        </p>
        <p>
          This Privacy Statement is an integral part of the General Terms and Conditions of hotel-natura.hr.
        </p>
        <p>
          Natura d.o.o. as a service provider of the website hotel-natura.hr adheres to the applicable regulations in order to protect the privacy of its customers or users, and in particular the General Regulation on the protection of personal data of the EU. This document describes how the processing manager of Master Media d.o.o. (hereinafter hotel-natura.hr) processes personal data.
        </p>
        <p>
          Customers of the hotel-natura.hr Website (hereinafter referred to as Customers) or Users receiving notifications of products and promotions of hotel-natura.hr (hereinafter referred to as Users) are instructed to read everything listed on this page in order to better understand data from Natura d.o.o. collects and processes, for what purpose, on what legal basis, with whom and why it shares them, what protective measures it implements, and what are your rights regarding access to personal data, correction, deletion and your right to object.
        </p>
        <p>
          Any Buyer or User who has any questions regarding personal data can send an e-mail to <a href="mailto:info@hotel-natura.hr">info@hotel-natura.hr</a>.
        </p>
        <p>
          By accepting this Privacy Statement by clicking on the registration of the Buyer or by registering on the hotel-natura website, the Buyer or the User confirms that he has read, understood and agrees with the processing of personal data as determined by this Statement.
        </p>
        <p>
        Natura d.o.o. and persons responsible for the maintenance of this Website, as well as directors, officers and agents of Natura d.o.o. , consider that all information contained on this website is accurate. However, there is no guarantee for the accuracy or reliability of the information provided herein and Natura d.o.o. does not acknowledge any liability for any direct or indirect loss or damage suffered by the recipient relying on anything stated or omitted from the hotel-natura.hr website.
        </p>
        <p>

© Natura d.o.o.

All content available on the website of Natura d.o.o. (hotel-natura.hr) is protected by copyright. In addition to permitted use, Natura d.o.o. gives site visitors permission to download and exhibit copyrighted material for private purposes only. For reproduction or use of copyrighted material outside of such purpose, permission must be sought directly from Natura d.o.o. If permission is granted, it must be subject to the requirement that the name of the copyright owner and his interest in the material be indicated when reproducing or quoting the material, any part thereof or the whole.
        </p>
        <h2>For what purpose does hotel-natura.hr collect and process personal data?</h2>
        <p>
          hotel-natura.hr collects and processes personal data of the Customer of the website hotel-natura.hr for the purpose of conducting a secure authentication of Customers who access the Website hotel-natura.hr, realization of contracts for the purchase of goods or services, delivery of goods to the Buyer, communication with the Buyer, possible legal procedures related to the realization of the contract, and partly we apply automated processing processes to constantly improve our processes in the interest of Buyers.  offer for the Customer to be more individual and to adapt their offer of products and services as much as possible to the habits and needs of the Customer.
        </p>
        <p>
          hotel-natura.hr collects and processes the personal data of the User of the notification of products and promotions for the purpose of sending notifications, invitations to participate in contests via e-mail, social networks, or other communication channels to which you have previously applied, any legal procedures related to the implementation of the contract, in order to create a user profile with the aim of receiving individual information, market research and improving the efficiency and quality of our services.

        </p>
        <p>
        We do not collect information about children. If we believe that such information has been passed on to us without the consent of the parents or guardians of children under the age of 16, we will remove it without delay. Minors under the age of 16 may not use the hotel-natura.hr website. No part of the hotel-natura.hr website is designed to attract anyone under the age of 16.
        </p>
        <h2>Period in which personal data will be stored</h2>
        <p>
        hotel-natura.hr stores personal data of registered Customers of the hotel-natura.hr website for the period until the purpose of processing is achieved, which is the period until the moment when it is active registration and six months after the termination of the registration of the Buyer in which period any complaints from the previous period will be resolved. hotel-natura.hr stores the personal data of registered Users of the recipient of notifications about products and promotions for the period while the purpose of processing is being achieved, which is the period until the moment when the registration is active.
        </p>
        <h2>Access and correction of personal data</h2>
        <p>
        The Buyer and the User at any time have the opportunity to access their personal data by registering on the site and access to “My Account” where the Customer can revise their personal data shared with hotel-natura. hr. The Buyer and the User can request and receive from hotel-natura.hr complete information about the personal data stored, as well as their correction by sending an e-mail to the e-mail address of the Personal Data Protection Officer: info@hotel-natura.hr.
        </p>
        <h2>Deletion of personal data (Right to Forget)</h2>
        <p>
          The Buyer and the User have the right to request the deletion of personal data (right to forget) at any time. The customer can do so by sending a request to the personal data protection officer at <a href="mailto:info@hotel-natura.hr">info@hotel-natura.hr</a>, and the data will be deleted without delay.
        </p>
        <p>
          The user has the option of submitting a request for deletion in each notification received from hotel-natura.hr via email or can send an e-mail to the personal data protection officer at <a href="mailto:info@master-media.hr">info@master-media.hr</a> stating that their personal data should be deleted.
        </p>
        <h2>The right to object</h2>
        <p>
        If, despite all measures taken for the protection of personal data, you believe that you have grounds for objection, please contact the e-mail address of the personal data protection officer <a href="mailto:info@hotel-natura.hr">info@hotel-natura.hr</a>. Of course, you have the right to report to the supervisory body of the Personal Data Protection Agency.
        </p>
        <h2>Security measures for personal data protection</h2>
        <p>
        The collected data are in electronic form and protected by an SSL certificate that encrypts the data and thus ensures that communication between the customer’s computer or the User and hotel-natura.hr takes place via a secure protocol. Hotel-natura.hr takes data protection seriously and takes various precautions to protect personal data. Unfortunately, no data transmission over the Internet, or any wireless network, can be 100% secure. As a consequence, although hotel-natura.hr implements reasonable data protection safeguards, it cannot guarantee the protection of any information transmitted to or from the hotel-natura.hr website and is not responsible for the actions of any third party that receives such information. hotel-natura.hr may decide to store personal data with service providers within the EU and only exceptionally outside the EU. It will only do so if there is a European Commission decision on adequacy for that country and if a guarantee and compliance with binding data protection regulations has been agreed.
        </p>
        <h2>Modification of the Privacy Statement</h2>
        <p>
        This privacy statement may be amended by hotel-natura.hr at any time by publishing the amended text of the Privacy Statement on the hotel-natura.hr website. That is why hotel-natura.hr is an invitation and Customers and Users to periodically review this Privacy Statement which will indicate new changes, if any. If the Buyer or the User does not agree with this Privacy Statement, we instruct the Buyer and the User to leave and not to access and use the Hotel-natura.hr Website. The change to the privacy statement takes effect immediately after its publication on the hotel-natura.hr website. The continued use of the website hotel-natura.hr by the Buyer and the User after the entry into force of the changes, implies that the Buyer and the User confirm and accept the amended Privacy Statement.
        </p>
        <h2>Rules on the Handling of Cookies</h2>
        <p>
          Cookies and other tracking technologies can be used on the hotel-natura.hr website in various ways, e.g., for the hotel-natura.hr website to function, for traffic analysis, or for marketing purposes.
        </p>
        <p>
          A cookie is a small piece of information that a website stores on a visitor’s computer or mobile device. The cookie allows the website to “remember” the actions of visitors from previous visits. Most browsers allow the use of cookies, but the user can delete them at any time or set a ban on storing them in the browser. The most common reasons for using cookies are: visitor identification, remembering specific user preferences, helping to enter information already entered during previous visits, collecting data for analysis, and promotional campaigns.
        </p>

        <h2>Cookies can be classified as:</h2>

        <h3>Temporary cookies (Session cookies)</h3>
        <p>
          These are stored on a computer and are automatically deleted after closing the web browser. They allow the website to receive temporary information, such as comments or shopping cart status.
        </p>

        <h3>Persistent cookies</h3>
        <p>
          These remain in your web browser after closing and usually expire. hotel-natura.hr uses persistent cookies to facilitate access to registered users. They store information such as username and password, which allows you to use the “remember me” functionality when logging in, so you do not have to enter your username and password every time you visit hotel-natura.hr.
        </p>

        <h3>First-page cookies</h3>
        <p>
          These come from the website you have visited and can be temporary or permanent. They allow web pages to store data that is used when a user visits the web page again.
        </p>

        <h3>Third-party cookies</h3>
        <p>
          There are several external services that store limited cookies for the user (Facebook, Instagram, Google Analytics, and AdWords). These cookies are not set by hotel-natura.hr and most often serve to help interpret user behavior and marketing purposes.
        </p>

        <h2>What options are available to you?</h2>
        <p>
          In browser settings such as Internet Explorer, Safari, Firefox, or Chrome, you can specify which cookies you want to accept and which you want to decline. Where you can find the settings depends on the type of your browser. Use the “help” option in your browser to find the settings you need.
        </p>
        <p>
          If you choose the option that you do not want to accept certain cookies, you may not be able to use certain functions on the hotel-natura.hr website.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;