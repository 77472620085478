import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./RoomsPage.css";
import divider from "../assets/icons/divider.png";
import doubleBalconyPetFriendly from "../assets/rooms/double_balcony_petfr.webp";
import quadRoom from "../assets/rooms/quad_room.webp";
import twinRoom from "../assets/rooms/twinroom.webp";
import doubleRoom from "../assets/rooms/double_room.webp";
import EmailSubscription from "../components/EmailSubscription";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const supportedLangs = ["en", "hr", "it", "de"];

const RoomsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const langFromURL = location.pathname.split("/")[1];
    const currentLang = supportedLangs.includes(langFromURL) ? langFromURL : "en";

  return (
    <div className="rooms-page">
      <Helmet>
        <title>{t("Rooms | Hotel Natura")}</title>
        <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
        <meta
          name="description"
          content={t(
          "Explore the diverse room types offered by Hotel Natura, each designed to ensure comfort, relaxation, and a memorable stay."
          )}
        />
      </Helmet>
      <section className="rooms-header">
        <img src={divider} alt={t("divider")} className="divider-icon" />
        <h1>{t("EXPLORE OUR")} <strong>{t("ROOM")}</strong> {t("OFFERS")}</h1>
        <p>{t("Hotel Natura provides guests with a choice of four distinct room types, all featuring common amenities such as a wardrobe, desk, satellite TV, minibar, safe, and mosquito nets on all room windows.")}</p>
        <p>{t("The first room type comprises the main area, a bathroom with a shower, and a balcony offering a picturesque view of the pool. The second room type, equally valuable, includes the main area, a bathroom with a shower, and a hallway but lacks a balcony unlike the first type. The third room type mirrors the second, differing only in offering two single beds.")}</p>
        <p>{t("The fourth and final room type, a quadruple room, also includes a wardrobe, desk, satellite TV, minibar, safe, and mosquito nets on all windows. This spacious accommodation consists of two rooms: one with a double bed and a bathroom with a shower, and the other with two single beds and a bathroom with a shower. Both rooms feature a balcony overlooking the garden.")}</p>
        <p>{t("Irrespective of the room type chosen, guests are provided with clean linen and towels that can be replaced upon request every four days.")}</p>
      </section>

      <section className="rooms-gallery">
  <div className="room">
    <Link to={`/${currentLang}/booking`} className="room-image-container">
      <img src={doubleBalconyPetFriendly} alt={t("Double Room with Balcony – Pet Friendly")} />
    </Link>
    <h2>{t("DOUBLE ROOM WITH BALCONY – PET FRIENDLY")}</h2>
    <p>{t("Pet-Friendly Double Room with Balcony.")}</p>
  </div>

  <div className="room">
    <Link to={`/${currentLang}/booking`} className="room-image-container">
      <img src={quadRoom} alt={t("Quad Room")} />
    </Link>
    <h2>{t("QUADRUPLE ROOM")}</h2>
    <p>{t("The suite comprises a hallway leading to two separate rooms.")}</p>
  </div>

  <div className="room">
    <Link to={`/${currentLang}/booking`} className="room-image-container">
      <img src={doubleRoom} alt={t("Double Room with Balcony")} />
    </Link>
    <h2>{t("DOUBLE ROOM WITH BALCONY")}</h2>
    <p>{t("Enjoy our Double Room featuring a comfortable double bed.")}</p>
  </div>

  <div className="room">
    <Link to={`/${currentLang}/booking`} className="room-image-container">
      <img src={twinRoom} alt={t("Twin Room")} />
    </Link>
    <h2>{t("TWIN ROOM")}</h2>
    <p>{t("Experience our spacious room featuring a hallway, two single beds.")}</p>
  </div>

  <div className="room">
    <Link to={`/${currentLang}/booking`} className="room-image-container">
      <img src={doubleRoom} alt={t("Double Room")} />
    </Link>
    <h2>{t("DOUBLE ROOM")}</h2>
    <p>{t("The room features a comfortable double bed, wardrobe, desk, and satellite TV.")}</p>
  </div>
</section>
      <EmailSubscription />
    </div>
  );
};

export default RoomsPage;