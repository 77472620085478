import React from "react";
import "./PrivateHire.css";
import { useTranslation } from "react-i18next";
import DividerIcon from "../assets/icons/divider.png";
import weddingImg from "../assets/naturaWedd.webp";
import partyImg from "../assets/naturaParty.webp";
import EmailSubscription from "../components/EmailSubscription";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const PrivateHire = () => {
  const { t } = useTranslation();
  const location = useLocation();


  return (
    <div className="private-hire">
        <Helmet>
    <title>{t("Private Hire | Hotel Natura")}</title>
    <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
    <meta
        name="description"
        content={t(
            "Host your perfect event at Hotel Natura with our exclusive private hire options. Whether it's a wedding, birthday, team-building event, or celebration, we provide a luxurious setting tailored to your needs."
        )}
    />
</Helmet>

    <section className="about-header">
        <img src={DividerIcon} alt={t("divider")} className="divider-icon" />
        <h1>{t("HOST UNFORGETTABLE EVENTS")}</h1>
        <p className="about-intro">
            {t("Discover the perfect venue for your private celebrations at Hotel Natura. From intimate gatherings to grand events, we provide a luxurious and tailored setting to make your special occasion unforgettable.")}
        </p>
    </section>

    <section className="event-section">
        <div className="event-text">
        <h2>{t("CELEBRATE IN STYLE!")}</h2>
        <p>{t("Hotel Natura is the perfect venue for your special occasions:")}</p>
        <ul>
            <li>{t("Baptisms")}</li>
            <li>{t("Bachelor Parties")}</li>
            <li>{t("Parties & Celebrations")}</li>
            <li>{t("Weddings")}</li>
            <li>{t("Birthdays")}</li>
            <li>{t("Team Building Events")}</li>
            <li>{t("Corporate Gatherings")}</li>
            <li>{t("Anniversaries")}</li>
            <li>{t("Engagement Parties")}</li>
            <li>{t("Exclusive Dinners")}</li>
        </ul>
        <p>{t("Enjoy a luxurious and unforgettable experience with a tailored setup for your needs. Our professional team ensures every detail is handled with perfection, from catering to decorations, making your event truly special.")}</p>
        <p>{t("Book now to secure your date and let us create the perfect atmosphere for your occasion.")}</p>
    </div>
    <div className="event-images">
        <img src={weddingImg} alt={t("Wedding Event")} className="image wedding" />
        <img src={partyImg} alt={t("Party Celebration")} className="image party" />
    </div>
    </section>
    <button onClick={() => window.location.href='/about-us/'} className="about-button">
    {t("More About Hotel Natura")}
    </button>

      <EmailSubscription />
    </div>
  );
};

export default PrivateHire;