import React, { useState } from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import "./PaymentForm.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

const PaymentForm = ({ amount, currency, onPaymentSuccess, onPaymentError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const { t } = useTranslation();
  const formattedAmount = (amount / 100).toFixed(2);

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      alert("Stripe is not loaded yet.");
      return;
    }

    setIsProcessing(true);

    try {
      const response = await fetch("https://danda.hr/proxy.php?endpoint=create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount, currency }),
      });

      const { clientSecret, error, id: paymentIntentId } = await response.json();

      if (error) {
        setIsProcessing(false);
        onPaymentError(error);
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholderName,
          },
        },
      });

      if (stripeError) {
        setIsProcessing(false);
        onPaymentError(stripeError.message);
        return;
      }

      setIsProcessing(false);
      onPaymentSuccess(paymentIntentId);
    } catch (error) {
      setIsProcessing(false);
      onPaymentError(error.message);
    }
  };

  return (
    <form onSubmit={handlePaymentSubmit} className="payment-form">
  <h2 className="step-title">
    <FontAwesomeIcon icon={faCreditCard} className="step-icon" /> {t("Payment Information")}
  </h2>
  <p className="step-helper-text">
    {t("Please enter your payment details to complete the booking. Your information is secure and encrypted.")}
  </p>

      <div className="total-price-display">
        <p>{t("Total Price")}:</p>
        <span>€{formattedAmount}</span>
      </div>

      <div className="form-row">
        <div className="form-field">
          <label>
            {t("Cardholder Name")}
            <p className="step-helper-text">{t("Enter the full name as displayed on your card.")}</p>
            <input
              type="text"
              placeholder={t("Enter cardholder name")}
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
              required
            />
          </label>
        </div>

        <div className="form-field">
          <label>
            {t("Card Number")}
            <p className="step-helper-text">{t("Enter your 16-digit card number.")}</p>
            <div className="card-element">
              <CardNumberElement />
            </div>
          </label>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field">
          <label>
            {t("Expiration Date")}
            <p className="step-helper-text">{t("Enter the expiry date in MM/YY format.")}</p>
            <div className="card-element">
              <CardExpiryElement />
            </div>
          </label>
        </div>
        <div className="form-field">
          <label>
            {t("CVC")}
            <p className="step-helper-text">{t("Enter the 3-digit security code on the back of your card.")}</p>
            <div className="card-element">
              <CardCvcElement />
            </div>
          </label>
        </div>
      </div>

      <div className="payment-buttons">
        <p className="helper-text">{t("By clicking 'Book Stay', you authorize the payment and confirm your reservation.")} <br /> {t("Your booking will be finalized upon successful payment processing.")}</p>
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="next-button"
        >
          {isProcessing ? t("Processing...") : t("Book Stay")}
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;