import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import "./TermsOfUse.css";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-container">
      <Helmet>
        <title>{t("Terms and Conditions | Hotel Natura")}</title>
        <link rel="canonical" href="https://hotel-natura.com/terms-and-conditions/" />
        <meta
          name="description"
          content={t(
            "Review the Terms and Conditions for using the Hotel Natura website, detailing important information regarding website usage and legal responsibilities."
          )}
        />
      </Helmet>
      <div className="terms-content">
        <h1 className="terms-title">TERMS AND CONDITIONS</h1>
        <h2 className="terms-subtitle">Terms and conditions of M.D. NATURA d.o.o. website usage</h2>
        <p>
          Your access to this website and all information contained herein is subject to these terms and conditions. You shall be considered to have provided your consent to the following legal terms by viewing this homepage:
        </p>

        <ul>
          <li>
          You hereby accept and are subject to the Croatian law as the only applicable law for interpreting, application and legal consequences related to all permissions, restrictions and terms of usage of this website. All requests and disputes arising from using this website or related to it shall be resolved exclusively before Croatian courts. M.D. NATURA d.o.o. does not guarantee that the material contained on this website is appropriate or available to users in certain locations i.e. it shall not be liable for access to the material by the visitors of this website. It shall be considered, in any case, that every use of this website is always done at users’ own discretion i.e. with permission of legal representative (guardian), when necessary, and on behalf of persons whose data are linked to specific IP addresses.
          </li>
          <li>
          M.D. NATURA d.o.o. or its related entities own the copyrights and other related rights, according to positive legal regulations, to the entire content of this website and all such rights are reserved or properly assigned.
          </li>
          <li>
          All the information contained on this website is purely informative and cannot be used without authorisation for commercial purposes or distributed to third persons. The information about particular products and services on this website is subject to certain limitations.
          </li>
          <li>
          The information which M.D. NATURA d.o.o. publishes at this website is considered accurate and reliable at the time when it is uploaded. However, M.D. NATURA d.o.o. draws your attention to the fact that sometimes the information provided on this website might not be entirely accurate, true or reliable at the moment a user is using the website. The information provided at the website cannot be considered as solid grounds for making important personal or business decisions. Thus, you agree that M.D. NATURA d.o.o. shall not be liable for not meeting your potential expectations. M.D. NATURA d.o.o. shall not be liable for any direct or indirect, intangible or tangible damage, any losses or expenses arising from using or not being able to use the information available on this website.
          </li>
          <li>
          M.D. NATURA d.o.o. can, at any time and without prior notice, update or change any data stored on this website.
          </li>
          <li>
          Visitors of this website should be aware that sending private messages via emails on the network can be unsecure and subject to unauthorized access of third persons or to wrong delivery. M.D. NATURA d.o.o. shall not be liable for message security and privacy. In this regard, it shall be entitled to reproduce, use, dispose of and distribute them to third parties without limitations, and it shall, at its own discretion, use ideas, drafts, experience or other forms of knowledge contained in such messages for any purpose without providing anything in return.
          </li>
          <li>
          Any reproduction, duplication or distribution of the design or the background of plavalaguna.com website, individual elements of the website, the design of Hotel Natura logo and brands or any other graphic, verbal or figurative content published on this website is strictly prohibited without the express written consent from M.D. NATURA d.o.o.
          </li>
        </ul>

        <p>
        The rules of data protection and use of cookies are shown in separate documents available on this website: <a href="/privacy-policy">Privacy Policy</a> and <a href="/cookie-policy">Cookie Policy</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;