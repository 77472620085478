import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.css";
import logo from "../assets/logo.png";
import globeImg from "../assets/icons/globe.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faArrowRight} from "@fortawesome/free-solid-svg-icons";

const supportedLangs = ["en", "hr", "it", "de"];

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const hoverTimeout = useRef(null);
    const hamburgerRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const pathSegments = location.pathname.split("/").filter(Boolean);
    const isHomePage = pathSegments.length === 0 || (pathSegments.length === 1 && supportedLangs.includes(pathSegments[0]));

    useEffect(() => {
        const handleScroll = () => {
            if (isHomePage) {
                setIsScrolled(window.scrollY > 100);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isHomePage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                hamburgerRef.current &&
                !hamburgerRef.current.contains(event.target)
            ) {
                setIsMobileMenuOpen(false);
            }

            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setLanguageDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const storedLang = localStorage.getItem("selectedLanguage");
        if (storedLang && supportedLangs.includes(storedLang) && storedLang !== currentLang) {
            changeLanguage(storedLang);
        }
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    const isMobile = window.innerWidth <= 768;

    const langFromURL = location.pathname.split("/")[1];
    const currentLang = supportedLangs.includes(langFromURL) ? langFromURL : "en";

    const changeLanguage = (langCode) => {
        let pathSegments = location.pathname.split("/").filter(segment => segment !== "");

        if (supportedLangs.includes(pathSegments[0])) {
            pathSegments[0] = langCode;
        } else {
            pathSegments.unshift(langCode);
        }

        const newPath = `/${pathSegments.join("/")}`;

        if (location.pathname !== newPath) {
            i18n.changeLanguage(langCode);
            localStorage.setItem("selectedLanguage", langCode);
            navigate(newPath, { replace: true });
        }
        setLanguageDropdownOpen(false);
        clearTimeout(hoverTimeout.current);
    };

    const handleMouseEnter = () => {
        if (!isMobile) {
            clearTimeout(hoverTimeout.current);
            setLanguageDropdownOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile) {
            hoverTimeout.current = setTimeout(() => {
                setLanguageDropdownOpen(false);
            }, 300);
        }
    };

    return (
        <header className={`header ${isHomePage ? (isScrolled ? "scrolled" : "transparent") : "scrolled"}`}>
            <div className="header-container">
                <Link to={`/${currentLang}/`} className="header-logo">
                    <img src={logo} alt="Hotel Natura" />
                </Link>
    
                <nav className={`header-links ${isMobileMenuOpen ? "mobile-open" : ""}`} ref={mobileMenuRef}>
                    <ul>
                        <li>
                            <NavLink
                                to={`/${currentLang}/about-us/`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={closeMobileMenu}
                            >
                                {t("About Us")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/${currentLang}/rooms/`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={closeMobileMenu}
                            >
                                {t("Our Rooms")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/${currentLang}/gallery/`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={closeMobileMenu}
                            >
                                {t("Gallery")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/${currentLang}/private-hire/`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={closeMobileMenu}
                            >
                                {t("Private Hire")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/${currentLang}/contact-us/`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                onClick={closeMobileMenu}
                            >
                                {t("Contact Us")}
                            </NavLink>
                        </li>
    
                        {isMobile && (
                            <li className="mobile-language-dropdown">
                                <div
                                    className={`language-dropdown ${languageDropdownOpen ? "open" : ""}`}
                                    ref={dropdownRef}
                                >
                                    <button
                                        className="language-button"
                                        onClick={() => setLanguageDropdownOpen((prev) => !prev)}
                                        aria-label="Change Language"
                                    >
                                        <img src={globeImg} alt="Globe Icon" className="globe-icon" /> 
                                    </button>
                                    {languageDropdownOpen && (
                                        <ul className="dropdown-menu">
                                            {supportedLangs.map((lang) => (
                                                <li key={lang}>
                                                    <button onClick={() => changeLanguage(lang)}>
                                                        <div className="language-option">
                                                            <img
                                                                src={`https://flagcdn.com/w40/${lang === 'en' ? 'gb' : lang}.png`}
                                                                alt={`${lang} Flag`}
                                                                className="flag-icon"
                                                            />
                                                            <span>{lang.toUpperCase()}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
                </nav>
    
                <div className="header-info">
                    <a href="tel:+385911256755" className="phone-link" aria-label="Call Hotel Natura at +385 91 125 6755">
                        <FontAwesomeIcon icon={faPhone} className="phone-icon" /> +385 91 125 6755
                    </a>
                </div>
    
                <Link to={`/${currentLang}/booking`} className="book-button">
                    {t("Online Booking")} <FontAwesomeIcon icon={faArrowRight} className="book-icon" />
                </Link>
    
                {!isMobile && (
                    <div
                        className={`language-dropdown ${languageDropdownOpen ? "open" : ""}`}
                        ref={dropdownRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button
                            className="language-button"
                            onClick={() => setLanguageDropdownOpen((prev) => !prev)}
                            aria-label="Change Language"
                        >
                            <img src={globeImg} alt="Globe Icon" className="globe-icon" />
                        </button>
                        {languageDropdownOpen && (
                            <ul className="dropdown-menu">
                                {supportedLangs.map((lang) => (
                                    <li key={lang}>
                                        <button onClick={() => changeLanguage(lang)}>
                                            <div className="language-option">
                                                <img
                                                    src={`https://flagcdn.com/w40/${lang === 'en' ? 'gb' : lang}.png`}
                                                    alt={`${lang} Flag`}
                                                    className="flag-icon"
                                                />
                                                <span>{lang.toUpperCase()}</span>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
    
                <button
                    className="hamburger-menu"
                    ref={hamburgerRef}
                    onClick={toggleMobileMenu}
                    aria-expanded={isMobileMenuOpen}
                    aria-label="Toggle navigation"
                >
                    {isMobileMenuOpen ? "✕" : "☰"}
                </button>
            </div>
        </header>
    );
    
};
export default Header;