import React from "react";
import HeroSection from "../components/HeroSection";
import FeatureGrid from "../components/FeatureGrid";
import RoomsCarousel from "../components/RoomsCarousel";
import ServicesGrid from "../components/ServicesGrid";
import HotelInfoTabs from "../components/HotelInfoTabs";
import EmailSubscription from "../components/EmailSubscription";
import DividerIcon from "../assets/icons/divider.png";
import LogoIcon from "../assets/crni-logo.png";
import { useTranslation } from "react-i18next";
import "./HomePage.css";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const HomePage = () => {
    const { t } = useTranslation();
      const location = useLocation();
    
    return (
        <div className="homepage">
            <Helmet>
                <title>{t("Hotel Natura | Relax in Nature & Comfort")}</title>
                <link rel="canonical" href={`https://hotel-natura.com${location.pathname}`} />
                <meta
                    name="description"
                    content={t(
                        "Experience an unforgettable holiday at Hotel Natura, offering peaceful surroundings, luxurious rooms, and exceptional service amidst beautiful natural scenery."
                    )}
                />
            </Helmet>
            <HeroSection />
            <section className="info-section">
                <img src={DividerIcon} alt="Divider" className="divider-icon" />
                <h1>
                    {t("SPEND")} <strong>{t("UNFORGETTABLE")}</strong> {t("HOLIDAYS IN A NATURAL ENVIRONMENT")}
                </h1>
                <p>
                    {t("The name of the hotel alone reveals a lot about its location and the benefits offered to its guests.")}{" "}
                    {t("For most people, a vacation is synonymous with nature, greenery, peace, and relaxation, far away from the everyday noise of the city.")}
                </p>
            </section>
            <FeatureGrid />
            <section className="room-info-hp">
                <img src={LogoIcon} alt="Divider" className="logo-icon" />
                <h2>{t("YOU WILL HAVE A CORNER OF HEAVEN IN OUR ROOMS EQUIPPED WITH HIGH STANDARDS")}</h2>
                <p>
                    {t("At Hotel Natura, guests can choose from four types of rooms, each equipped with common amenities including a wardrobe, desk, satellite TV, minibar, safe, and mosquito nets on all room windows.")}
                </p>
            </section>
            <RoomsCarousel />
            <ServicesGrid />
            <HotelInfoTabs />
            <EmailSubscription />
        </div>
    );
};

export default HomePage;