import React from "react";
import { useTranslation } from "react-i18next";
import "./Complaint.css";
import { Helmet } from "react-helmet-async";

const Complaint = () => {
    const { t } = useTranslation();
  
  return (
    <div className="complaint-container">
      <Helmet>
        <title>{t("How to Submit a Written Complaint | Hotel Natura")}</title>
        <link rel="canonical" href="https://hotel-natura.com/how-to-submit-a-written-complaint/" />
        <meta
          name="description"
          content={t(
            "Learn how to submit a written complaint regarding products or services provided by Hotel Natura, in accordance with the Consumer Protection Act."
          )}
        />
</Helmet>
      <div className="complaint-content">
        <h1 className="complaint-title">HOW TO SUBMIT A WRITTEN COMPLAINT</h1>
        <p>
          Under Article 10 of the Consumer Protection Act, you may file a written complaint related to a purchased product or a rendered service in person, by post, or via email at the following addresses:
        </p>
        <p>
          <strong>M.D. NATURA d.o.o.</strong>, Vilanija, Klija 18b, Umag, Republic of Croatia, or at <a href="mailto:hotelnaturavilanija@gmail.com">hotelnaturavilanija@gmail.com</a>.
        </p>
        <p>
          Please make sure to state your name and contact information needed to respond to the complaint. We will send you a written reply no later than 15 days from the date of receipt to the address indicated in your letter, i.e., to the address from which it was sent.
        </p>
      </div>
    </div>
  );
};

export default Complaint;